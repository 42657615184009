<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding">
            <img class="teding-img" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="2">
            شركة سينوبك انجينيرنج قروب السعودية المحدودة <br />SINOPEC
            ENGINEERING GROUP SAUDI CO., LTD.
          </td>
        </tr>
        <tr>
          <td class="text-left">日期:</td>
          <td class="text-center"></td>
          <td class="text-right">التاريخ:</td>
        </tr>
        <tr>
          <td class="text-left">S/N编号:</td>
          <td class="text-center"></td>
          <td class="text-right">الرقم التسلسلي:</td>
        </tr>
        <tr>
          <td class="text-center text-left-bold" colspan="3">
            Salary Certificate 薪资证明 شهادة التعريف بالراتب
          </td>
        </tr>
        <tr>
          <td class="text-left text-30">To:</td>
          <td class="text-center text-40"></td>
          <td class="text-right text-30">إلى:</td>
        </tr>
        <tr>
          <td class="text-left">Company Name:</td>
          <td class="text-center"></td>
          <td class="text-right">إسم الشركة:</td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            تشهد شركة سينوبك انجينيرنج قروب السعودية المحدودة أن الموظف المذكورة
            بياناته أدناه يعمل لحسابها و يتقاضى راتبًا شهريًا إجماليًا كما هو
            مفصل أدناه:
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="3">
            This document certifies that the employee, whose details are
            provided below, is currently working for our organization. The
            employee's information is listed as follows:
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="3">
            兹证明，该员工当前就职于本公司，其个人及薪资信息如下：
          </td>
        </tr>
        <tr>
          <td class="text-center text-center-bold" colspan="3">
            The Basic Information of Employee 员工基本信息 معلومات الموظف
            الأساسية
          </td>
        </tr>
        <tr>
          <td class="text-left">Employee ID 员工编码</td>
          <td class="text-center"></td>
          <td class="text-right">الرقم الوظيفي</td>
        </tr>
        <tr>
          <td class="text-left">Employee Name 员工姓名</td>
          <td class="text-center"></td>
          <td class="text-right">إسم الموظف</td>
        </tr>
        <tr>
          <td class="text-left">Name in English 拼音姓名</td>
          <td class="text-center"></td>
          <td class="text-right">الاسم بالانجليزي</td>
        </tr>
        <tr>
          <td class="text-left">Nationality 国籍</td>
          <td class="text-center"></td>
          <td class="text-right">الجنسية</td>
        </tr>
        <tr>
          <td class="text-left">Passport Number 护照号</td>
          <td class="text-center"></td>
          <td class="text-right">رقم الجواز</td>
        </tr>
        <tr>
          <td class="text-left">IQAMA/National ID 暂住证号</td>
          <td class="text-center"></td>
          <td class="text-right">رقم الهوية الوطنية / الاقامة</td>
        </tr>
        <tr>
          <td class="text-left">ID of Home Country 员工母国身份证号</td>
          <td class="text-center"></td>
          <td class="text-right">رقم الهوية في دولته</td>
        </tr>
        <tr>
          <td class="text-left">Profession on IQAMA 职业类别</td>
          <td class="text-center"></td>
          <td class="text-right">المهنة في الاقامة</td>
        </tr>
        <tr>
          <td class="text-left">Job Position 岗位名称</td>
          <td class="text-center"></td>
          <td class="text-right">المسمى الوظيفي</td>
        </tr>
        <tr>
          <td class="text-left">Date to Join Company 到本单位日期</td>
          <td class="text-center"></td>
          <td class="text-right">تاريخ التعيين</td>
        </tr>
        <tr>
          <td class="text-center text-center-bold" colspan="3">
            The Details of Employee Salary 员工薪资信息 (SAR/M ) تفاصيل راتب
            الموظف
          </td>
        </tr>
        <tr>
          <td class="text-left">Basic Salary 基本工资</td>
          <td class="text-center"></td>
          <td class="text-right">الراتب الأساسي</td>
        </tr>
        <tr>
          <td class="text-left">Housing Allowance 住房补贴</td>
          <td class="text-center"></td>
          <td class="text-right">بدل السكن</td>
        </tr>
        <tr>
          <td class="text-left">Transportation Allowance 交通补贴</td>
          <td class="text-center"></td>
          <td class="text-right">بدل المواصلات</td>
        </tr>
        <tr>
          <td class="text-left">Other Benefit 其他收益</td>
          <td class="text-center"></td>
          <td class="text-right">بدلات أخرى</td>
        </tr>
        <tr>
          <td class="text-left">Gross Amount 总工资</td>
          <td class="text-center"></td>
          <td class="text-right">الاجمالي</td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            تم إصدار هذه الشهادة بناءً على طلب الموظف لأغراض قانونية ولا يجوز
            تفسيرها على أنها التزام. أو التزام من قبل الشركة بتحمل أي مسؤولية.
            وهذه الشهادة صالحة لمدة ثلاثة أشهر من تاريخ إصدارها. إذا كنت بحاجة
            إلى مزيد من المعلومات أو التوضيح، فلا تتردد في الاتصال بنا.
          </td>
        </tr>

        <tr>
          <td class="text-left" colspan="3">
            This certificate has been issued upon the request of employee for
            legal purposes and shall not be construed as a commitment or
            obligation by the company to assume any liability. This certificate
            shall remain valid for a period of three months from the date of
            issuance. Should you require further information or clarification,
            please do not hesitate to contact us.
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="3">
            此证明系应员工本人要求开具，仅用于法律用途，并不构成公司对其承担任何责任的承诺。该证明自签发之日起三个月内有效。如需进一步信息，请随时与我们联系。
          </td>
        </tr>
        <tr>
          <td class="text-left">
            HR Manager of Company<br />
            中东公司人力资源部经理
          </td>
          <td class="text-center">
            <div class="img-box">
              <!-- <img src="@/assets/image/teding.jpg" /> -->
            </div>
          </td>
          <td class="text-right">مدير الموارد البشرية</td>
        </tr>
        <tr>
          <td class="text-left">
            General Manager of Company<br />
            中东公司经理
          </td>
          <td class="text-center">
            <div class="img-box"></div>
          </td>
          <td class="text-right">المدير العام</td>
        </tr>
        <tr>
          <td class="text-left">
            Company Stamp<br />
            公司名称（盖章）
          </td>
          <td class="text-center">
            <div class="img-box">
              <!-- <img src="@/assets/image/teding.jpg" /> -->
            </div>
          </td>
          <td class="text-right">ختم الشركة</td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            P.O Box 76819 AI Khobar 31952, Saudi Arabia - Sinopec(SEG) Building,Prince Sultan St. - Tel: +996 (013) 849 2821 / 849 2824 - Fax: + 966
            (013) 887 9067 Website:www.segroup.cn/en/
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getCostCategoryTree,
} from "@/api/user";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
    };
  },

  methods: {
    geteditData(data1, data2, data3, params) {
      console.log("params", params);
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = params.TableDate;
      this.ListerDept = params.ListerDept;
      this.CostCategoryName = params.CostCategoryName;
      this.Amount = params.Amount;
      this.Duration = params.Duration;
      this.ITMSANO = params.ITMSANO;
      this.BankInformation = params.BankInformation;
      this.BankAccountName = params.BankAccountName;
      this.BankName = params.BankName;
      this.BankAccount = params.BankAccount;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.HROfficer = params.HROfficer;
      this.HRSuperintendent = params.HRSuperintendent;
      this.ExecutiveDirector = params.ExecutiveDirector;
      this.ApplicationNumber = params.ApplicationNumber;
      this.CompanyNameofBeneficiary = params.CompanyNameofBeneficiary;
      this.CRNumberofBeneficiary = params.CRNumberofBeneficiary;
      this.BankCode = params.BankCode;
      this.Note = params.Note;
      this.CostCategory = params.CostCategoryIDs.split(",");
      this.CostCategory = this.CostCategory.map(Number);
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          console.log("11111", this.CostAllocationDetails);
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("11111", this.CostAllocationDetails);
    },
  },
  created() {
    this.TableDate = getNewDate();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getCostCategoryTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .tbody {
    width: 100%;
    position: relative;
  }
  .teding {
    max-width: 100%;
  }
  .teding-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      padding: 10px 5px;
    }
    .text-center {
      text-align: center;
      font-size: 14px;
      padding: 10px 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-bold {
      font-weight: bolder;
    }
    .text-size {
      font-size: 12px;
    }
    .text-title {
      text-align: center;
      font-size: 14px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 14px;
      padding: 10px 5px;
    }
    .text-red {
      color: red;
    }
    .text-left {
      text-align: left;
      font-size: 14px;
      padding: 10px 5px;
      line-height: 18px;
      .div-title {
        margin-top: 16px;
        // padding-left: 10px;
        span {
          // color: red;
          font-weight: bold;
        }
      }
      .div-content {
        // padding-left: 10px;
        text-indent: 1em;
        margin-top: 5px;
        margin-bottom: 16px;
      }
      .herder-title {
        font-weight: bolder;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .div-bold {
        font-weight: bolder;
        margin-bottom: 20px;
      }
      .div-nobold {
        margin-bottom: 20px;
      }
    }
    .text-30 {
      min-width: 30% !important;
    }
    .text-40 {
      min-width: 40% !important;
    }
    .text-left-bold {
      font-weight: bolder;
      font-size: 20px;
    }
    .text-center-bold {
      font-weight: bolder;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.img-box >>> img {
  max-width: 100px;
}
</style>